<template>
  <div class="newsdetail">
    <div class="banner">
      <img :src="bannerInfo.logo" class="bannerBg "/>
    </div>
    <div class="news_main content_w" v-if="fieldShow">
      <div class="newstit1 animate__animated animate__fadeInDown">{{detail.title}}</div>
      <div class="newstit2 animate__animated animate__fadeInDown">发布时间：{{detail.add_time}}</div>
      <div class="mymain animate__animated animate__fadeInUp">
        <div class="maintit" v-html="detail.content"></div>
        <div class="source">发布来源：{{detail.source}}</div>
        <div class="return" @click="goback">返回</div>
      </div>
    </div>
    <div v-if="!fieldShow" class="height2r"></div>
  </div>
</template>

<script>
import banner from "../components/home/banner.vue";
export default {
  name: "newsdetail",
   components: {
    banner
  },
  data() {
    return {
      showTwo:false,
      fieldShow:false,
      inwidth: window.innerWidth,
      newsid: this.$route.query.newsid,
      detail: {},
      prev_next:{},
      bannerInfo:{}
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll,true);
     setTimeout((res) => {
        this.fieldShow = true;
      }, 2500);
      this.news_detail();
      this.banner_inner();
  },
  methods: {
    
    banner_inner() {
			let that = this;
			let data = {
        banner_id:4
      };
			that.$api.banner_inner(data).then(res => {
				if (res.status == 1) {
          that.bannerInfo = res.data.banner
				} else {
					layer.msg(res.msg);
				}
			});
    },
    onScroll(){
				let inHeight = window.innerHeight
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
      }
      if(this.inwidth >= 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1560 && this.inwidth < 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1400 && this.inwidth < 1560 && scrollTop > 660){
				  this.showTwo = true
				}else if(this.inwidth >= 1200 && this.inwidth < 1400 && scrollTop > 600){
				  this.showTwo = true
				}else if(this.inwidth >= 1024 && this.inwidth < 1200 && scrollTop > 540){
				  this.showTwo = true
				}
		},
    goback() {
      //返回
      this.$router.go(-1);
    },
    news_detail() {
      var that = this;
      let data = { id: that.newsid };
      that.$api.news_detail(data).then((res) => {
        if (res.status == 1) {
           this.detail = res.data.detail;
           let regex = new RegExp('<img', 'gi')
           this.detail.content =  this.detail.content.replace(regex, `<img style="width: 100%; height: auto"`);
          console.log('this.detail',this.detail)
           this.prev_next = res.data.prev_next;
        } else {
          layer.msg(res.msg);
        }
      });
    },
  },
};
</script>

<style >
.maintit p{
  font-size: .14rem;
}
.newsdetail {
  width: 100%;
  background: #fff;
}
.newsdetail .banner {
  width: 100%;
}
.newsdetail .banner img {
  width: 100%;
  display: block;
}
.news_main {
  padding-bottom: 0.5rem;
}
.source {
  width: 90%;
  box-sizing: border-box;
  padding: 0.3rem 0;
  margin: auto;
  color: #4d4d4d;
  font-size: 0.16rem;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}
.return {
  width: 2rem;
  height: 0.45rem;
  line-height: 0.45rem;
  text-align: center;
  margin: 0.5rem auto 0;
  color: #a21003;
  border: 1px solid #a21003;
  font-size: 0.16rem;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  cursor: pointer;
}
.return:hover {
  background: #a21003;
  color: #ffffff;
}
.maintit {
  width: 90%;
  margin: auto;
  font-size: 0.14rem;
  color: #808080;
  line-height: 0.3rem;
  padding-bottom: 0.3rem;
}
.maintit p,
.maintit h5 {
  margin-bottom: 0.2rem;
  text-align: justify;
  text-justify: inter-ideograph;
}

.maintit img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.maintit img {
  max-width: 100%;
  display: block;
}
.newstit2 {
  color: #808080;
  font-size: 0.16rem;
  padding-bottom: 0.3rem;
  text-align: center;
}
.newstit1 {
  color: #4d4d4d;
  font-size: 0.24rem;
  font-weight: bold;
  padding: 0.5rem 0 0.1rem;
  text-align: center;
}
.maintit img {
  max-width: 100%;
  margin: 0 auto;
}
 .banner{
    height: 7.1rem;
  }
.bannerBg {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

@media (max-width: 1200px) {
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
  .newstit1{
    font-size: 18px;
  }
  .newstit2{
    font-size: 12px;
  }
  .maintit p,.source,.return{
    font-size: 14px;
    line-height: 24px;
  }
  .return{
    height: .6rem;
    line-height: .6rem;
  }
  .banner{
    height: 5.1rem;
  }
}

</style>
